// fetch() polyfill for making API calls.
const whatwgFetch = require('whatwg-fetch');

const match = /Edge\/(\d+)/.exec(navigator.userAgent);
if (match && +match[1] <= 17) {
  window.fetch = whatwgFetch.fetch;
}

// Includes polyfill
if (!Array.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.includes = function includesPolyfill(needle, fromIndex) {
    return this.indexOf(needle, fromIndex) > -1;
  };
}
