import onReady from '../common/on-ready';

onReady(() => {
  const avcCheck = document.getElementById('avc');
  if (avcCheck) {
    avcCheck.parentNode.removeChild(avcCheck);
  }
  if (avcCheck && !localStorage.getItem('verified') && !sessionStorage.getItem('verified')) {
    document.body.classList.add('avc');

    const avcOverlay = document.createElement('div');
    avcOverlay.className = 'avc-popup';

    const avcPanel = avcCheck.content.cloneNode(true);
    avcOverlay.appendChild(avcPanel);

    document.body.appendChild(avcOverlay);

    const avcVerified = avcOverlay.querySelectorAll('.avc-verified');
    if (avcVerified) {
      avcVerified.forEach((node) => {
        node.addEventListener('click', (e) => {
          e.preventDefault();
          if (typeof e.target.dataset.session !== 'undefined') {
            sessionStorage.setItem('verified', true);
          } else {
            localStorage.setItem('verified', true);
          }
          document.body.removeChild(avcOverlay);
        });
      });
    }
  }
});
