import onReady from '../common/on-ready';

let timeout = null;

const clearText = (event) => {
  const successElement = event.target.querySelector('.form-success');
  const errorElement = event.target.querySelector('.form-errors');

  if (successElement) {
    successElement.textContent = '';
  }
  if (errorElement) {
    errorElement.textContent = '';
  }
}

const doClearText = (event) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  timeout = setTimeout(clearText.bind(this, event), 5000);
}

const onSubmitSuccess = (event, response, json, onResponse = null) => {
  const formElements = event.target.elements;
  if (onResponse) {
    onResponse(response, json);
  } else if (json.type === 'success' && json.message) {
    const successElement = event.target.querySelector('.form-success');
    if (successElement) {
      successElement.textContent = json.message;
    }
    if (json.redirect) {
      window.location.href = json.redirect;
    }
  }
  Array.prototype.slice.call(formElements, 0).forEach((element) => {
    if (element.classList.contains('form-clear')) {
      element.value = '';
    }
  });
}

const onSubmit = (event, onResponse = null) => {
  event.preventDefault();
  const formElements = event.target.elements;
  const query = [];
  Array.prototype.slice.call(formElements, 0).forEach((element) => {
    if (element.name) {
      if (element.type === 'checkbox' && !element.checked) {
        return;
      }
      query.push(`${encodeURIComponent(element.name)}=${encodeURIComponent(element.value)}`);
    }
  });
  fetch(event.target.action, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: query.join('&'),
  }).then((response) => {
    response.json().then((json) => {
      event.preventDefault();
      const errorElement = event.target.querySelector('.form-errors');
      const successElement = event.target.querySelector('.form-success');
      if (successElement) {
        successElement.textContent = '';
      }
      if (errorElement) {
        errorElement.textContent = '';
        if (response.status >= 400) {
          errorElement.textContent = json.message;
        } else {
          onSubmitSuccess(event, response, json, onResponse);
        }
        doClearText(event);
      } else if (response.status < 400) {
        onSubmitSuccess(event, response, json, onResponse);
        doClearText(event);
      }
      return false;
    });
  });
  return false;
}

const onSubmitLogin = (event) => onSubmit(event, () => {
  const redirect = document.location.href.replace(/^.+?author-panel\/(.+?)\/?$/, '$1');
  if (!redirect || redirect === 'login') {
    document.location.href = '/author-panel'
  } else {
    document.location.href = document.location.href.replace(/^(.+?author-panel\/).+?\/?$/, `$1${redirect}`);
  }
});

onReady(() => {
  document.querySelectorAll('.submit-form').forEach((element) => {
    element.addEventListener('submit', onSubmit);
  });

  document.querySelectorAll('.login-form').forEach((element) => {
    element.addEventListener('submit', onSubmitLogin);
  });
});
