export default (callback) => {
  if (document.readyState === 'complete') {
    callback();
  } else {
    const onReady = () => {
      document.removeEventListener('DOMContentLoaded', onReady);
      window.removeEventListener('load', onReady);
      callback();
    }
    document.addEventListener('DOMContentLoaded', onReady);
    window.addEventListener('load', onReady);
  }
}
