import onReady from '../common/on-ready';
import tippy from 'tippy.js';

const guideLibrary = {};

onReady(() => {
  document.querySelectorAll('[data-guide]').forEach((element) => {
    tippy(element, {
      onShow(instance) {
        const entry = element.dataset.guide;

        if (guideLibrary[entry]) {
          instance.setContent(guideLibrary[entry]);
        } else if (window.sessionStorage && window.sessionStorage.getItem(`guide:${entry}`)) {
          guideLibrary[entry] = window.sessionStorage.getItem(`guide:${entry}`);
          instance.setContent(guideLibrary[entry]);
        } else {
          fetch(`/guide/${entry}`, {
            method: 'POST',
          }).then((response) => {
            response.json().then((json) => {
              if (window.sessionStorage) {
                window.sessionStorage.setItem(`guide:${entry}`, json.short_description);
              }
              guideLibrary[entry] = json.short_description;
              instance.setContent(guideLibrary[entry]);
            });
          });
        }
      }
    })
  });
});
